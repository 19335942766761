import { hydrateRoot } from 'react-dom/client'
import { AppContainer } from './components/AppContainer/AppContainer'
import { SearchApp } from './components/_search/SearchApp/SearchApp'
import store from './store/store'
import { Header } from './components/_header/Header/Header'
import { Footer } from './components/Footer/Footer'
import { Instances } from './components/Instances/Instances'

const AppJSX = () => <AppContainer
    store={store}
  >
    <Header />
    <SearchApp />
    <Footer />
    <Instances />
  </AppContainer>

export default AppJSX

if(typeof window.location !== 'undefined') {
  hydrateRoot(
    document.getElementById('root'),
    <AppJSX />
  )
}
