/**
 * Created by taylor.houston on 11/1/2018.
 */
import { FACET_GROUP_TOGGLE, FACET_GROUP_EXPAND } from '../constants/facetGroupConstants'

export function toggleAccordionAction (facetGroupName) {
  return (dispatch) => {
    dispatch({ type: FACET_GROUP_TOGGLE, name: facetGroupName })
  }
}

export function toggleAccordionExpandedAction (facetGroupName) {
  return (dispatch) => {
    dispatch({
      type: FACET_GROUP_EXPAND,
      name: facetGroupName
    })
  }
}
