import React from 'react'
import { connect } from 'react-redux'
import { compareRemoveItemAction, compareHideBar, compareProducts } from '../../../actions/compareActions'
import styled from 'styled-components'

const ConnectedComponent = (props) => {
  const removeCompareHandler = (e) => {
    props.compareRemoveItem({ productPartNumber: e.target.getAttribute('data-product-id') })
    if (props.compareItems.length - 1 === 0) {
      props.hideCompareBar()
    }
  }

  const hideCompareBarHandler = () => {
    props.hideCompareBar()
  }

  const compareProductsHandler = () => {
    document.cookie = `_gpHist=${window.location.href}|Search`
    props.compareProduct()
  }

  return (
    <Comparison data-testid='comparison-bar' className={`${props.show ? 'ComparisonBar--active' : ''}`}>
      <ComparisonButtonContainer>
        {props.compareItems.length > 1
          ?
          <ComparisonBarButton onClick={compareProductsHandler}>
            Compare <ItemSpan>Items</ItemSpan>
          </ComparisonBarButton>
          : <ComparisonBarButtonDisabled>Compare <ItemSpanDisabled>Items</ItemSpanDisabled></ComparisonBarButtonDisabled>
        }
      </ComparisonButtonContainer>
      <ComparisonItemContainer>
        {props.compareItems.map((item) => (
          <ComparisonItem key={item.productPartNumber}>
            <ComparisonBarImgContainer>
              <ComparisonImage
                alt={item.productName}
                src={`https://media.swansonvitamins.com/images/items/80/${item.productPartNumber}.jpg`}
              />
            </ComparisonBarImgContainer>
            <ComparisonBarContent>
              <ComparisonItemText>{item.productName}</ComparisonItemText>
              <ComparisonItemText>{item.productDetails}</ComparisonItemText>
            </ComparisonBarContent>
            <ComparisonBarItemX
              data-testid={`comparison-bar__item-x-${item.productPartNumber}`}
              data-product-id={item.productPartNumber}
              onClick={removeCompareHandler}
            >
              &times;
            </ComparisonBarItemX>
          </ComparisonItem>
        ))}
      </ComparisonItemContainer>
      <ComparisonBarX
        data-testid='comparison-bar__x'
        onClick={hideCompareBarHandler}
      >
        &times;
      </ComparisonBarX>
    </Comparison>
  )
}

const mapDispatchToProps = (dispatch) => (
  {
    compareRemoveItem: (item) => dispatch(compareRemoveItemAction(item)),
    hideCompareBar: () => dispatch(compareHideBar()),
    compareProduct: () => dispatch(compareProducts())
  }
)

const mapStateToProps = (state) => (
  {
    compareItems: state.compare.items
  }
)

const Comparison = styled.div`
  display: none;
  position: fixed;
  height: 4rem;
  background-color: ${props => props.theme.lightGrey};
  bottom: -4rem;
  z-index: 10;
  max-width: 1400px;
  transition: bottom 1s;
  margin: 0 auto;
  left: 0;
  right: 0;
  @media (min-width: 1280px) {
    display: flex;
  }
  &.ComparisonBar--active {
      bottom: 0;
  }
`
const ComparisonImage = styled.img`
 height: 3.6rem;
 max-width: 100%;
`
const ComparisonButtonContainer = styled.div`
  width: 15rem;
  align-items: center;
  justify-content: center;
  display: flex;
  
  
`
const ComparisonItemContainer = styled.div`
  display: flex;
  width: calc(100% - 200px);
`
const ComparisonItem = styled.div`
  background-color: ${props => props.theme.white};
  border-right: 0.1rem solid ${props => props.theme.mediumGrey};
  display: flex;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
`
const ComparisonItemText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.5rem;
`
const ComparisonBarX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  color: ${props => props.theme.primary};
  font-size: 2.5rem;
  cursor: pointer;
`
const ComparisonBarItemX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.black};
  font-size: 2.4rem;
  cursor: pointer;
  width: 30px;
`
const ComparisonBarContent = styled.div`
  width: calc(100% - 66px);
  padding: 0 0.5rem;
  justify-content: center;
  display: flex;
  flex-flow: column;
`
const ComparisonBarImgContainer = styled.div`
  width: 36px;
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.lg}){
   display: block;
  }
`
const ComparisonBarButton = styled.button`
  width: 100%;
  cursor: pointer;
  height: 40px;
  background-color: ${props => props.theme.darkestOrange};
  color: ${props => props.theme.white};
  border-radius: 0.8rem;
  border: 0 solid ${props => props.theme.darkestOrange};
`
const ComparisonBarButtonDisabled = styled.button`
  width: 100%;
  height: 40px;
  background-color: ${props => props.theme.white};
  pointer-events: none;
  color: ${props => props.theme.darkGrey};
  border-radius: 0.8rem;
  border: 0.1rem solid ${props => props.theme.darkGrey};
`
const ItemSpan = styled.span`
  display: none;
  color: ${props => props.theme.white};
  @media(min-width: ${props => props.theme.breakpoints.md}) {
    display: inline;
  }
`
const ItemSpanDisabled = styled.span`
  display: none;
  color: ${props => props.theme.darkGrey};
  @media(min-width: ${props => props.theme.breakpoints.md}) {
    display: inline;
  }
`
export const ComparisonBar = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
