import React from 'react'
import styled from 'styled-components'
import { Col12 } from '../../_styled/BootStrapGrid'

export const Banner = (props) => {
  return (
    <BannerComp>
      <div dangerouslySetInnerHTML={{ __html: props.html }} />
    </BannerComp>
  )
}

const BannerComp = styled.div`
  display: flex;
  ${Col12};
  justify-content: center;
  & .gbb-section {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & .gbb-img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  & .gbb-a{
    padding-right: .6rem;
  }
  & .gbb-a :last-of-type {
    padding: 0;
  }
  @media (max-width: 768px) {
    & .gb-mobile-banner-img {
      width: 100%;
    }
  }
`
