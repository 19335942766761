import styled from 'styled-components'

export const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  padding-top: 3rem;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 1.5rem 0;
  ${props => props.styles};
  @media(min-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    padding: 0 1.5rem 1.5rem 1.5rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width: ${props => props.theme.breakpoints.lg}){
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media(min-width: ${props => props.theme.breakpoints.xl}){
    flex: 0 0 25%;
    max-width: 25%;
    padding: 1.5rem;
  }
`
