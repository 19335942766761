import React from 'react'
import styled from 'styled-components'


export const ProductUpsellSlot = ({
  upSellHtml
}) => {
    return (
        <ProductComp className='plp-upSellSlot'>
            <div dangerouslySetInnerHTML={{ __html: upSellHtml }} />
        </ProductComp>
    )
}

const ProductComp = styled.div`
  display: flex;
  width: 25%;
  justify-content: center;
  @media (max-width: 574px) {
     width: 100%;
  }
  @media (min-width: 575px) and (max-width: 991px) {
     width: 42%;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
     width: 30%;
  }
  & .gbb-section {
    display: flex;
    width: 25%;
    padding: 0 1.25rem;
  }
  & .gbb-img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  & .gbb-a{
    padding-right: .6rem;
  }
  & .gbb-a :last-of-type {
    padding: 0;
  }
  @media (max-width: 768px) {
    & .gb-mobile-banner-img {
      width: 100%;
    }
  }
`
