import React from 'react'
import { connect } from 'react-redux'
import { OptionButton } from '../../_inputs/OptionButton/OptionButton'
import styled from 'styled-components'
import {
  compareAddItemAction,
  compareHideBar,
  compareRemoveItemAction,
  compareShowBar
} from '../../../actions/compareActions'
import { Product } from '../../Product/Product'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'
import { utagLink } from "../../../tealium.js"

const ConnectedComponent = (props) => {
  const comparisonChangeHandler = (e) => {
    const item = {
      productPartNumber: props.product.productPartNumber,
      productName: props.product.productName,
      productDetails: props.product.productDetails
    }
    let compareItemCount = props.compareItems.length
    if (e.value) {
      props.compareAddItem(item)
      if (compareItemCount < 3) {
        compareItemCount++
      }
    } else {
      props.compareRemoveItem(item)
      compareItemCount--
    }
    if (compareItemCount <= 0) {
      props.compareBarHide()
    } else {
      props.compareBarShow()
    }
  }

  const additionalAddToCartCode = () => {
    utagLink({
      'tealium_event': 'atc_popup_trigger'
    })
    props.setInstance({
      name: 'atcModal',
      data: {
        quantity: 1,
        product: props.product
      }
    })
  }

  return (
    <>
      <Product
        {...props}
        product={props.product}
        classes={props.classes}
        additionalAfterAddToCartCode={additionalAddToCartCode}
        displayATCBanner={false}
      />
      <OptionButtonFalse>
        <OptionButton
          inputType='checkbox'
          name={`name${props.product.productPartNumber}`}
          returnState={comparisonChangeHandler}
          labelText='compare products'
          value='true'
          checked={props.compareItems?.some(item => item['productPartNumber'] === props.product.productPartNumber)}
          testId={`compare-checkbox-${props.product.productPartNumber}`}
        />
      </OptionButtonFalse>
    </>
  )
}
const OptionButtonFalse = styled.div`
  display: none;
  margin: 1rem auto;
  @media (min-width: 1280px){
    display: block;
  }
`
ConnectedComponent.defaultProps = {
  direction: 'vertical'
}

const mapDispatchToProps = dispatch => (
  {
    compareAddItem: (item) => dispatch(compareAddItemAction(item)),
    compareRemoveItem: (item) => dispatch(compareRemoveItemAction(item)),
    compareBarShow: () => dispatch(compareShowBar()),
    compareBarHide: () => dispatch(compareHideBar()),
    setInstance: (payload) => dispatch(sharedSetInstanceAction(payload))
  }
)

const mapStateToProps = (state) => (
  {
    compareItems: state.compare.items,
    compareBarVisible: state.compare.barVisible
  }
)

export const ProductWithComparison = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
