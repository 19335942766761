
import React from 'react'
import { connect } from 'react-redux'
import { FacetGroup } from '../FacetGroup/FacetGroup'
import styled from 'styled-components'
import { updateURLsAction, clearURLsAction } from '../../../actions/updateURLsAction'
import { FieldSet } from '../../_styled/FieldSet'
import { VisuallyHidden } from '../../_styled/VisuallyHidden'

const ConnectedComponent = props => {
  const removeRefinementHandler = (e) => {
    e.preventDefault()
    props.updateURLs(e.currentTarget.getAttribute('data-key'))
  }

  const removeAllRefinementHandler = () => {
    props.clearURLs()
  }

  let facetGroups = []
  for (let index = 0; index < Object.keys(props.facets.facets.facetKeys).length; index++) {
    if (props.facets.facets.facetKeys[index] in props.facets) {
      facetGroups.push(<FacetGroup key={`facetGroup${index}`} facetGroupName={props.facets.facets.facetKeys[index]} facets={props.facets.facets.facetsMap[props.facets.facets.facetKeys[index]]} />)
    }
  }
  if (facetGroups.length === 0) {
    facetGroups.push(<SideBarMessage>Remove filter to see more matches</SideBarMessage>)
  }

  return (
    <SideBarComp data-site-area='left-navigation' className={`${props.classes} ${props.visible ? 'Sidebar--active' : ''}`}>
      <SideBarSelectionHeader>
        <SideBarSelectionText>Your Selection</SideBarSelectionText>
        <CursorPointer>
          {props.breadcrumbs.length > 1 &&
              <ClearAllButton role='button' aria-live='polite'  aria-label='Clear all selected category' className='cTest_clear-all' onClick={removeAllRefinementHandler}>
                  <SideBarX>&times;</SideBarX>
                  <span role="button" className='cTest_clear-all' aria-label="clear all">clear all</span>
              </ClearAllButton>
          }
        </CursorPointer>
      </SideBarSelectionHeader>
      <SideBarSelection>
        {props.breadcrumbs.map((breadcrumb, index) => (
          <SideBarSelectedBtn
            as='a'
            role='button'
            aria-live='polite'
            aria-label={`${breadcrumb.name} Cleared`}
            href={breadcrumb.mkSeoUrl}
            title={breadcrumb.removeNavSeoURL ? `${breadcrumb.name}` : ``}
            onClick={removeRefinementHandler}
            key={index}
            data-key={breadcrumb.removeNavSeoURL}
          >
              <SideBarX className={`${breadcrumb.removeNavSeoURL ? '' : ' Sidebar__x--hidden'} `}>&times;</SideBarX>{breadcrumb.name}
          </SideBarSelectedBtn>
        ))}
      </SideBarSelection>
      <SideBarContainer>
        <SideBarFacetContainer>
          <FieldSet>
            <VisuallyHidden as='legend'>filter search results</VisuallyHidden>
            {facetGroups.map((facetGroup) => {
              return facetGroup
            })}
          </FieldSet>
        </SideBarFacetContainer>
      </SideBarContainer>
    </SideBarComp>
  )
}
const SideBarComp = styled.section`
  width: 100%;
  top: 55px;
  position: absolute;
  max-height: 0;
  z-index: 5;
  overflow: hidden;
  border: 0;
  padding: 0 .6rem;
  margin-top: 1.5rem;
  background: ${props => props.theme.white};
  &.Sidebar--active{
    max-height: 3000px;
    transition: max-height 2s;
  }
  @media(min-width: ${props => props.theme.breakpoints.md}) {
    width: 28rem;
    top: 0;
    border-right: 0.1rem solid ${props => props.theme.lightGrey};
    background-color: ${props => props.theme.white};
    position: relative;
    max-height: fit-content;
    overflow: visible;
  }
`
const SideBarSelectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-bottom: 0.1rem solid ${props => props.theme.lightGrey};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 115px;
  }`
const SideBarSelectionText = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2.5rem;
`
const CursorPointer = styled.span`
  margin-left: auto;
  cursor: pointer;
`
const ClearAllButton = styled.button`
  cursor: pointer;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  border: 0;
  @media(max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 6rem;
  }
`
const SideBarX = styled.span`
  color: ${props => props.theme.darkGrey};
  font-size: 1.6rem;
  padding-right: 0.5rem;
  &.Sidebar__x--hidden{
    visibility: hidden;
  }
`
const SideBarSelection = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
`
const SideBarSelectedBtn = styled.button`
  line-height: 1.8rem;
  font-size: 1.5rem;
  cursor: pointer;
  background: transparent;
  color: ${props => props.theme.black};
  width: 100%;
  display: inline-flex;
  padding-right: 1.5rem;
  margin: 1rem 0;
  align-items: center;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: ${props => props.theme.black};
  }
  @media(min-width: ${props => props.theme.breakpoints.md}) {
    padding-right: 0;
    display: flex;
  }
`
const SideBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const SideBarFacetContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`

const SideBarMessage = styled.div`
  padding-top: .6rem;
`
const mapStateToProps = state => (
    {
        facets: state.facetGroup,
        breadcrumbs: state.breadcrumb.breadcrumb
    }
)

const mapDispatchToProps = dispatch => (
    {
        updateURLs: (breadcrumb) => dispatch(updateURLsAction(breadcrumb)),
        clearURLs: () => dispatch(clearURLsAction())
    }
)

export const Sidebar = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
