import React from 'react'
import styled from 'styled-components'


export const CategoryProductFlag = ({ type = 'seller' }) => {
  let color
  if (type === 'rated') {
    color = '#00833D'
  } else if (type === 'branded') {
    color = '#0F5777'
  } else if (type === 'viewed') {
    color = '#21543B'
  } else {
    color = '#0F5777'
  }
  return (
    <FlagDiv type={type} color={color}>
      {type === 'seller' &&
        <>Top Seller</>
      }
      {type === 'rated' &&
      <>Top Rated</>
      }
      {type === 'branded' &&
        <>Popular Brand</>
      }
      {type === 'viewed' &&
        <>Most Viewed</>
      }
      <CutOutDiv />
    </FlagDiv>
  )
}

const FlagDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: .6rem;
  width: 15rem;
  height: 3rem;
  position: relative;
  color: ${props => props.theme.white};
  background-color: ${props => props.color};
  margin-bottom: .6rem;
  @media(max-width: ${props => props.theme.breakpoints.sm}) {
    position: absolute;
    top:0;
  }
`
const CutOutDiv = styled.div`
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: -1.5rem;
  top: 0;
  background-color: ${props => props.theme.white};
  transform: rotate(45deg);
`
