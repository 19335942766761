import React, { useState } from 'react'
import styled, {css} from 'styled-components'
import { PlusIcon } from '../../_svgs/PlusIcon/PlusIcon'
import { AriaHidden } from '../../_styled/AriaHidden'

export const FooterInfo = props => {
  const [showMore, setShowMore] = useState(false)

  const clickHandler = (e) => {
    setShowMore(!showMore)
  }
  return (
    <div className='FooterInfo'>
      <div className='FooterInfo__top' dangerouslySetInnerHTML={{ __html: props.data.footertop }} />
      <FooterInfoShow data-testid={'FooterInfo__show'} onClick={clickHandler}>
        <span>read {showMore ? 'less' : 'more'}</span>
        <PlusIcon isMinus={showMore} styles={css`margin-left: 5px;`} />
      </FooterInfoShow>
      <AriaHidden data-testid='FooterInfo__bottom' isAriaHidden={!showMore} aria-hidden={!showMore} className='FooterInfo__bottom' dangerouslySetInnerHTML={{ __html: props.data.footerbottom }} />
    </div>
  )
}

const FooterInfoShow = styled.div`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
