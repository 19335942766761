import React from 'react'
import styled from 'styled-components'
import { CategoryProductFlag } from '../CategoryProductFlag/CategoryProductFlag'
import { ProductWithComparison } from '../ProductWithComparison/ProductWithComparison'
import { ProductContainer } from '../../_styled/ProductContainer'

export const CategoryListingSection = ({ products, title }) => {
  const flagTypes = ['seller', 'rated', 'viewed', 'branded']

  const product1 = products[0]?.result.find(el => el.adobeRecord !== undefined)
  const product2 = products[1]?.result.find(el => el.adobeRecord !== undefined)
  const product3 = products[2]?.result.find(el => el.adobeRecord !== undefined)
  const product4 = products[3]?.result.find(el => el.adobeRecord !== undefined)

  return (
    <ProductListingSection>
      <FeaturedH1>Featured for {title}</FeaturedH1>
      <ProductListingBorderDiv>
        {(products[0] && products[0].result.length > 0) &&
          <ProductContainer className='category-listing-trck' data-product-id={product1.adobeRecord.productPartNumber} data-location={`top_${flagTypes[0]}1`}>
            <CategoryProductFlag type={flagTypes[0]} />
            <ProductWithComparison
              product={product1.adobeRecord}
            />
          </ProductContainer>
        }
        {products[1] && products[1].result.length > 0 &&
          <ProductContainer className='category-listing-trck' data-product-id={product2.adobeRecord.productPartNumber} data-location={`top_${flagTypes[1]}2`}>
            <CategoryProductFlag type={flagTypes[1]} />
            <ProductWithComparison
              product={product2.adobeRecord}
            />
          </ProductContainer>
        }
        {products[2] && products[2].result.length > 0 &&
          <ProductContainer className='category-listing-trck' data-product-id={product3.adobeRecord.productPartNumber} data-location={`most_${flagTypes[2]}3`}>
            <CategoryProductFlag type={flagTypes[2]} />
            <ProductWithComparison
              product={product3.adobeRecord}
            />
          </ProductContainer>
        }
        {products[3] && products[3].result.length > 0 &&
          <ProductContainer className='category-listing-trck' data-product-id={product4.adobeRecord.productPartNumber} data-location={`popular_brand4`}>
            <CategoryProductFlag type={flagTypes[3]} />
            <ProductWithComparison
              product={product4.adobeRecord}
            />
          </ProductContainer>
        }

      </ProductListingBorderDiv>
    </ProductListingSection>
  )
}

const FeaturedH1 = styled.h1`
  font-size: 1.6rem;
`

const ProductListingBorderDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-width: .1rem 0;
  border-style: solid;
  border-color: ${props => props.theme.mediumGrey};
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`
const ProductListingSection = styled.section`
  width: 100%;
`
