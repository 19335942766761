import React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { OptionButton } from '../../_inputs/OptionButton/OptionButton'
import { toggleAccordionAction, toggleAccordionExpandedAction } from '../../../actions/toggleAccordionAction'
import { updateURLsAction } from '../../../actions/updateURLsAction'
import { closeMobileRefinement } from '../../../actions/toggleMobileRefinement'
import { PlusIcon } from '../../_svgs/PlusIcon/PlusIcon'
import { FieldSet } from '../../_styled/FieldSet'
import useAmplitude from '../../../hooks/useAmplitude'

const ConnectedComponent = props => {
  const { trackEvent: trackAmplitudeEvent } = useAmplitude()
  // max number to display without an ajax call. Should not be above 20
  let NUMBER_TO_DISPLAY = 5
  if (props.facetGroupName === 'Price') {
    NUMBER_TO_DISPLAY = 6
  }

  const clickHandler = () => {
    props.toggleAccordion(props.facetGroupName)
  }

  const clickFacetGroup = (e, value, facetDisplayName, facetGroupName) => {
    e.preventDefault()
    props.updateURLs(value)
    props.filterMenuClose()
    trackAmplitudeEvent('product listing filtered', {
      FilterGroupName: facetGroupName,
      FilterDetail: facetDisplayName
    })
  }

  const clickExpandHandler = () => {
    props.toggleExpanded(props.facetGroupName)
    if (typeof window.location !== 'undefined') {
      sessionStorage.setItem('toggleExpanded', props.facetGroupName)
    }
  }

  const clickRefinementHandler = (e) => {
    props.updateURLs(e.value)
    props.filterMenuClose()
  }

  const buildFacetList = (facets, startIndex, endIndex = facets.length) => {
    let jsxArray = []
    const idFromGroupName = props.facetGroupName.replace(/\s/g, '')
    for (let index = startIndex; index < endIndex; index++) {

      const noFollow = (
        (idFromGroupName === 'Form' ||
        idFromGroupName === 'Potency' ||
        idFromGroupName === 'PillSize' ||
        idFromGroupName === 'Price' ||
        idFromGroupName === 'SpecialOffers' ||
        idFromGroupName === 'Flavor' ||
        idFromGroupName === 'ReviewRating' ||
        idFromGroupName === 'Specialty') &&
        props.isNoIndex
      )

      const facetDisplayName = facets[index].displayName || facets[index].name
      const anchor = <FacetGroupAnchor
        className={`cTest_facetGroup-${facets[index].name.replace(/['&.,]/g, '').replace(/[\s\s+ ]/g, '-')}`}
        href={noFollow ? '/' : facets[index].mkSeoUrl}
        title={facetDisplayName}
        onClick={(e) => clickFacetGroup(e, facets[index].seoURL, facetDisplayName, props.facetGroupName)}>
        {facetDisplayName} ({facets[index].numResults})
      </FacetGroupAnchor>

      jsxArray.push(<li key={`${facets[index].name}${index}`}>
        <OptionButton
          styles={css`margin-bottom: .6rem;`}
          returnState={clickRefinementHandler}
          inputType='link'
          value={facets[index].seoURL}
          labelText={anchor}
          name='test'
          id={`${idFromGroupName}${index}`}
        />
      </li>)
    }
    return jsxArray
  }

  return (
    <Facet>
      <FacetGroupAccordion aria-expanded={props.isAccordionOpen(props.facetGroupName) ? 'true' : 'false'} onClick={clickHandler}>
        <FacetGroupHeading className={`cTest_facetAccordion-${props.facetGroupName}`}>{props.facetGroupName}</FacetGroupHeading>
        <PlusIcon
          styles={css`margin-left: auto;`}
          isMinus={props.isAccordionOpen(props.facetGroupName)}
        />
      </FacetGroupAccordion>
      <HiddenForAnimate className={`${props.isAccordionOpen(props.facetGroupName) ? 'slide-down-animate' : ''}`}>
        <FieldSet>
          <VisuallyHiddenLegend>filter search results</VisuallyHiddenLegend>
          <ListRemoveDefault>
            {buildFacetList(props.facets, 0, props.facets.length > NUMBER_TO_DISPLAY ? NUMBER_TO_DISPLAY : props.facets.length).map((facet) => (
              facet
            ))}
          </ListRemoveDefault>
        </FieldSet>
        <FieldSet>
          <VisuallyHiddenLegend>filter search results</VisuallyHiddenLegend>
          <ListDefaultHiddenAnimate className={`${props.isAccordionExpanded(props.facetGroupName) ? 'slide-down-animate' : ''}`}>
            {buildFacetList(props.facets, NUMBER_TO_DISPLAY).map((facet) => (
              facet
            ))}
          </ListDefaultHiddenAnimate>
        </FieldSet>
        {props.facets.length > NUMBER_TO_DISPLAY &&
          <FacetGroupExpanded rel="nofollow" aria-expanded={props.isAccordionExpanded(props.facetGroupName)} aria-label={props.isAccordionExpanded(props.facetGroupName) ? 'Showing less products for this category' : 'Showing more products for this category'} onClick={clickExpandHandler}>
            <PlusIcon classes={`cTest_facetShow-${props.facetGroupName}`} styles={css`margin-right: .6rem;`} isMinus={props.isAccordionExpanded(props.facetGroupName)} />
            show {props.isAccordionExpanded(props.facetGroupName) ? 'less' : 'more'}
          </FacetGroupExpanded>
        }

      </HiddenForAnimate>
    </Facet>
  )
}

ConnectedComponent.defaultProps = {
  facets: []
}

const mapStateToProps = state => (
  {
    isAccordionOpen: (facetGroupName) => (state.facetGroup[facetGroupName] ? state.facetGroup[facetGroupName].open : false),
    isAccordionExpanded: (facetGroupName) => (state.facetGroup[facetGroupName] ? state.facetGroup[facetGroupName].expanded : false),
    isNoIndex: state.app.index
  }
)

const mapDispatchToProps = dispatch => (
  {
    toggleAccordion: (facetGroupName) => dispatch(toggleAccordionAction(facetGroupName)),
    toggleExpanded: (facetGroupName) => dispatch(toggleAccordionExpandedAction(facetGroupName)),
    updateURLs: (url) => dispatch(updateURLsAction(url)),
    filterMenuClose: () => dispatch(closeMobileRefinement())
  }
)

const Facet = styled.div`
  border-top: 1px solid ${props => props.theme.lightGrey};
  margin: 0.25rem 0;
`

const FacetGroupHeading = styled.h2`
  font-size: 1.35rem;
  margin-left: -0.9rem;
  letter-spacing: .1rem;
`
const FacetGroupAccordion = styled.button`
  display: flex;
  align-items: center;
  height: 4rem;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  border: 0;
  padding : 0.2rem 1rem !important;
  background: transparent;
  color: ${props => props.theme.black};
  width: 100%;
`
const FacetGroupExpanded = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  color: ${props => props.theme.black};
  width: 70%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem; 
  border: 0;
  font-size: 1.6rem;
`
const FacetGroupAnchor = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  color: ${props => props.theme.black};
  &:hover {
   text-decoration: underline;
   text-decoration-color: ${props => props.theme.primary};
   color: ${props => props.theme.black};
  }
`
const ListRemoveDefault = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
const HiddenForAnimate = styled.div`
  max-height: 0;
  overflow: hidden;
  &.slide-down-animate {
     max-height: 22000px;
     transition: max-height 2.5s;
  }
`
const ListDefaultHiddenAnimate = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  &.slide-down-animate {
    max-height: 22000px;
    transition: max-height 2.5s;
  }
`
const VisuallyHiddenLegend = styled.legend`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const FacetGroup = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
