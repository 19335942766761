/**
 * Created by taylor.houston on 11/13/2018.
 */
import React from 'react'
import { connect } from 'react-redux'
import styled, {css} from 'styled-components'
import { PlusIcon } from '../../_svgs/PlusIcon/PlusIcon'
import { toggleMobileRefinement } from '../../../actions/toggleMobileRefinement'

const ConnectedComponent = props => {
  const clickHandler = () => {
    props.toggleMobileRefinement()
  }

  return (
    <SearchFilterBar>
      {props.didYouMean.length > 0 && <div>Did you mean? <a id='gbDYM' href={`/q?kw=${props.didYouMean}`}>{props.didYouMean}</a></div>}
      <TitleRefinementBarButton
        onClick={clickHandler}
      >
        <AppTopNavSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 15'>
          <title>refine_1</title>
          <rect fill='#00843d' width='20' height='4' />
          <rect fill='#00843d' x='2.5' y='5.68' width='15' height='4' />
          <rect fill='#00843d' x='6' y='11' width='8' height='4' />
        </AppTopNavSvg>
        <span>REFINE</span>
        <PlusIcon styles={css`margin-left: 24px`} isMinus={props.openRefinementMenu} />
      </TitleRefinementBarButton>
    </SearchFilterBar>
  )
}

const SearchFilterBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 0.5rem;
`
const TitleRefinementBarButton = styled.div`
  width: 125px;
  z-index: 6;
  align-items: center;
  margin-left: auto;
  display: flex;
 @media(min-width: ${props => props.theme.breakpoints.md}) {
  display: none;
 }
`
const AppTopNavSvg = styled.svg`
  height: 1.3rem;
  margin-right: 0.5rem;
`

ConnectedComponent.defaultProps = {
  classes: ''
}

const mapDispatchToProps = (dispatch) => (
  {
    toggleMobileRefinement: () => dispatch(toggleMobileRefinement())
  }
)

const mapStateToProps = (state) => (
  {
    openRefinementMenu: state.app.filterMenuOpen,
    didYouMean: state.app.didYouMean || []
  }
)

export const TitleRefinementBar = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
